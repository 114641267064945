@use '../core/style/vars';

$vmr-back-to-top-transition: transform 250ms cubic-bezier(0.33, 0.00, 0.00, 1.00);

.vmr-back-to-top-button {
  z-index: 98;
  right: 20px;
  bottom: 20px;
  width: 48px;
  height: 48px;
  border: none;
  display: flex;
  position: fixed;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: vars.$vmr-primary;
  transition: $vmr-back-to-top-transition;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);

  &:hover,
  &:hover svg {
    transform: scale(1.1);
  }

  svg {
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: vars.$vmr-white;
    transition: $vmr-back-to-top-transition;
  }

  &.vmr-noop-animation {
    transition: none;
    svg { transition: none; }
  }
}