@use '../core/style/vars';

#vmr-toast-container {
  &.vmr-dark-mode > .vmr-toast-ordered-list {
    --normal-bg: #{vars.$vmr-dark-alert-bg};
    --normal-border: #383838;
    --normal-text: var(--gray1);
    --success-bg: #{vars.$vmr-dark-alert-bg};
    --success-border: #1AAE1A;
    --success-text: #1AAE1A;
    --info-bg: #{vars.$vmr-dark-alert-bg};
    --info-border: #1A99C7;
    --info-text: #1A99C7;
    --warning-bg: #{vars.$vmr-dark-alert-bg};
    --warning-border: #{vars.$vmr-yellow};
    --warning-text: #{vars.$vmr-yellow};
    --error-bg: #{vars.$vmr-dark-alert-bg};
    --error-border: hsl(358, 100%, 81%);
    --error-text: hsl(358, 100%, 81%);

    .vmr-toast {
      .vmr-toast-button.vmr-toast-button-cancel {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  > .vmr-toast-ordered-list {
    --toast-icon-margin-start: -3px;
    --toast-icon-margin-end: 4px;
    --toast-svg-margin-start: -1px;
    --toast-svg-margin-end: 0px;
    --toast-button-margin-end: 0;
    --toast-button-margin-start: auto;
    --gray2: hsl(0, 0%, 97.3%);
    --gray5: hsl(0, 0%, 90.9%);
    --normal-bg: #2f3033;
    --normal-text: #faeeef;
    --normal-border: #{lighten(#2f3033, 7%)};

    --success-bg: #{vars.$vmr-secondary-green};
    --success-text: #{vars.$vmr-white};
    --success-border:  #{lighten(vars.$vmr-secondary-green, 7%)};

    --info-text: #{vars.$vmr-white};
    --info-bg: #{vars.$vmr-notification-blue};
    --info-border:  #{lighten(vars.$vmr-notification-blue, 7%)};

    --warning-bg: #{vars.$vmr-accent};
    --warning-text: #{vars.$vmr-dark-grey};
    --warning-border:  #{lighten(vars.$vmr-accent, 7%)};

    --error-bg: #{vars.$vmr-action-red};
    --error-text: #{vars.$vmr-white};
    --error-border:  #{lighten(vars.$vmr-action-red, 7%)};

    margin: 0;
    padding: 0;
    outline: none;
    position: fixed;
    list-style: none;
    z-index: 999999999;
    width: var(--width);
    box-sizing: border-box;

    &[data-x-position='center'] {
      left: 50%;
      transform: translateX(-50%);
    }

    &[data-x-position='right'] {
      right: max(var(--offset), env(safe-area-inset-right));
    }

    &[data-x-position='left'] {
      left: max(var(--offset), env(safe-area-inset-left));
    }

    &[data-y-position='top'] {
      top: max(var(--offset), env(safe-area-inset-top));
    }

    &[data-y-position='bottom'] {
      bottom: max(var(--offset), env(safe-area-inset-bottom));
    }

    @media (max-width: 600px) {
      position: fixed;
      --mobile-offset: 16px;
      right: var(--mobile-offset);
      left: var(--mobile-offset);
      width: 100%;

      &[data-x-position='center'] {
        transform: none;
        left: var(--mobile-offset);
        right: var(--mobile-offset);
      }

      &[data-x-position='left'] {
        left: var(--mobile-offset);
      }

      &[data-y-position='top'] {
        top: 20px;
      }

      &[data-y-position='bottom'] {
        bottom: 20px;
      }
    }

    .vmr-toast {
      --y: translateY(100%);
      --lift-amount: calc(var(--lift) * var(--gap));
      transform: var(--y);
      z-index: var(--z-index);
      opacity: 0;
      outline: none;
      filter: blur(0);
      touch-action: none;
      position: absolute;
      box-sizing: border-box;
      overflow-wrap: anywhere;
      padding: 16px;
      background: var(--normal-bg);
      border: 1px solid var(--normal-border);
      color: var(--normal-text);
      border-radius: vars.$vmr-container-shape;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
      width: var(--width);
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 6px;
      min-height: 58px;
      transition: transform 400ms, opacity 400ms, height 400ms, box-shadow 200ms;

      > * {
        transition: opacity 400ms;
      }

      &:focus-visible {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(0, 0, 0, 0.2);
      }

      &::after {
        left: 0;
        width: 100%;
        bottom: 100%;
        content: '';
        position: absolute;
        height: calc(var(--gap) + 1px);
      }

      &[data-y-position='top'] {
        top: 0;
        --lift: 1;
        --y: translateY(-100%);
        --lift-amount: calc(1 * var(--gap));
      }

      &[data-y-position='bottom'] {
        bottom: 0;
        --lift: -1;
        --y: translateY(100%);
        --lift-amount: calc(var(--lift) * var(--gap));
      }

      &.vmr-toast-deleted::before {
        inset: 0;
        content: '';
        position: absolute;
        transform: scaleY(2);
      }

      &.vmr-toast-mounted {
        --y: translateY(0);
        opacity: 1;
      }

      &.vmr-toast-mounted.vmr-toast-expanded {
        --y: translateY(calc(var(--lift) * var(--offset)));
        height: var(--initial-height);
      }

      &:not(.vmr-toast-visible) {
        opacity: 0;
        pointer-events: none;
      }

      &:not(.vmr-toast-first):not(.vmr-toast-expanded) {
        --scale: var(--toasts-before) * 0.05 + 1;
        --y: translateY(calc(var(--lift-amount) * var(--toasts-before))) scale(calc(-1 * var(--scale)));
        height: var(--front-toast-height);
      }

      &:not(.vmr-toast-first):not(.vmr-toast-expanded) > * {
        opacity: 0;
      }

      &.vmr-toast-deleted.vmr-toast-first {
        --y: translateY(calc(var(--lift) * -100%));
        opacity: 0;
      }

      &:not(.vmr-toast-first).vmr-toast-deleted.vmr-toast-expanded {
        --y: translateY(calc(var(--lift) * var(--offset) + var(--lift) * -100%));
        opacity: 0;
      }

      &:not(.vmr-toast-first):not(.vmr-toast-expanded).vmr-toast-deleted {
        --y: translateY(40%);
        opacity: 0;
        transition: transform 500ms, opacity 200ms;
      }

      // Bump up the height to make sure hover state doesn't get set to false.
      &.vmr-toast-deleted:not(.vmr-toast-first)::before {
        height: calc(var(--initial-height) + 20%);
      }

      @media (max-width: 600px) {
        left: 0;
        right: 0;
        width: calc(100% - 32px);
      }

      .vmr-toast-content {
        gap: 2px;
        display: flex;
        flex-direction: column;

        .vmr-toast-title {
          color: inherit;
          font-weight: 500;
          line-height: 1.5;
        }

        .vmr-toast-description {
          color: inherit;
          font-weight: 400;
          line-height: 1.4;
        }
      }

      .vmr-toast-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        display: flex;
        flex-shrink: 0;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        margin-right: var(--toast-icon-margin-end);
        margin-left: var(--toast-icon-margin-start);

        > * {
          flex-shrink: 0;
        }

        svg {
          margin-right: var(--toast-svg-margin-end);
          margin-left: var(--toast-svg-margin-start);
        }
      }

      .vmr-toast-button {
        height: 24px;
        border-radius: 4px;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 14px;
        flex-shrink: 0;
        border: none;
        cursor: pointer;
        outline: none;
        display: flex;
        align-items: center;
        transition: opacity 400ms, box-shadow 200ms;
        color: var(--normal-bg);
        background: var(--normal-text);
        margin-left: var(--toast-button-margin-start);
        margin-right: var(--toast-button-margin-end);

        &:focus-visible {
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
        }

        &:first-of-type {
          margin-right: var(--toast-button-margin-end);
          margin-left: var(--toast-button-margin-start);
        }

        &.vmr-toast-button-cancel {
          color: var(--normal-text);
          background: rgba(0, 0, 0, 0.08);
        }
      }

      .vmr-toast-button-close {
        height: 20px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: var(--normal-bg);
        color: var(--normal-text);
        border: 0;
        transform: translate(-35%, -35%);
        border-radius: 9999px;
        cursor: pointer;
        z-index: 1;
        box-shadow: 0 0 2px 1px rgba(237, 225, 226, .425);
        transition: opacity 100ms, background 200ms;

        &:focus-visible {
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(0, 0, 0, 0.2);
        }

        .vmr-icon {
          font-size: 13px;
        }
      }

      .vmr-progress-spinner {
        inset: 0;
        z-index: 10;
        overflow: visible;
        position: absolute;
      }

      &.vmr-toast-type-error {
        &, .vmr-toast-button-close {
          background: var(--error-bg);
          color: var(--error-text);
          border-color: var(--error-border);
        }
      }

      &.vmr-toast-type-success {
        &, .vmr-toast-button-close {
          background: var(--success-bg);
          color: var(--success-text);
          border-color: var(--success-border);
        }
      }

      &.vmr-toast-type-warning {
        &, .vmr-toast-button-close {
          background: var(--warning-bg);
          color: var(--warning-text);
          border-color: var(--warning-border);
        }

        .vmr-toast-button-close {
          box-shadow: 0 0 2px 1px rgba(47, 48, 51, .22);
        }
      }

      &.vmr-toast-type-info {
        &, .vmr-toast-button-close {
          background: var(--info-bg);
          color: var(--info-text);
          border-color: var(--info-border);
        }
      }
    }
  }
}