@use './vars';

@mixin vmr-icon-button-base(
  $size: #{vars.$vmr-control-height},
  $color: #{vars.$vmr-slate},
  $hover-bg-color: #efefef
) {
  width: $size;
  height: $size;
  color: $color;
  outline: none;
  user-select: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  border: none;
  text-decoration: none;
  cursor: pointer;
  overflow: visible;
  flex-shrink: 0;
  background-color: transparent;
  border-radius: 9999px;

  &:hover:not(&[disabled]),
  &:focus:not(&[disabled]) {
    background-color: #{rgba($hover-bg-color, 0.68)};
  }

  &[disabled] {
    opacity: 0.34;
    pointer-events: none;
  }

  $icon-size: calc(($size / 2) - 6px);

  .vmr-icon {
    height: $icon-size;
    width: $icon-size;
    font-size: $icon-size;
  }
}
